<template>
  <div>
    <!-- 公共头部 -->
    <Header :title="title"></Header>

    <div class="dataUpload-box">
      <!-- <img class="icon" src="@/assets/images/icon74.png" /> -->
      <div class="cate-title">在线学习情况证明</div>
      <table class="table">
        <tr>
          <td class="table-gray key">姓名：</td>
          <td>{{ proveDetail.name }}</td>
        </tr>
        <tr>
          <td class="table-gray key">总计学时：</td>
          <td>{{ proveDetail.zks }}</td>
        </tr>
        <tr>
          <td class="table-gray key">身份证号：</td>
          <td>{{ proveDetail.cardid }}</td>
        </tr>
        <tr>
          <td class="table-gray key">已完成学时：</td>
          <td>
            {{ proveDetail.yks }}
            <font color="#009d30" v-if="proveDetail.exam_result == '已达标'"
              >(已达标)</font
            >
            <font color="#ff1b1b" v-if="proveDetail.exam_result == '未达标'"
              >(未达标)</font
            >
          </td>
        </tr>
        <tr>
          <td class="table-gray key">培训项目：</td>
          <td>{{ proveDetail.subject_title }}</td>
        </tr>
        <tr>
          <td class="success_num table-gray key">模拟考试达标次数：</td>
          <td>{{ proveDetail.exam }}</td>
        </tr>
        <tr>
          <td class="table-gray key">所在班级：</td>
          <td>{{ proveDetail.classes_title }}</td>
        </tr>
        <tr>
          <td class="table-gray key">评价结果：</td>
          <td>
            <img
              v-if="proveDetail.result == '合格'"
              src="@/assets/images/icon75.png"
              align="middle"
              style="height: 0.6rem"
            />
            <img
              v-if="proveDetail.result == '不合格'"
              src="@/assets/images/icon76.png"
              align="middle"
              style="height: 0.6rem"
            />
          </td>
        </tr>
      </table>
      <table class="table1">
        <tr>
          <th style="width: 60%">课程名称</th>
          <th style="width: 20%">学时/h</th>
          <th style="width: 20%">是否完成</th>
        </tr>
        <tr v-for="(item, index) in proveDetail.lists" :key="index">
          <td>{{ item.title }} ---- {{ item.teacher }}</td>
          <td>{{ item.zks }}学时</td>
          <td>
            <font color="#ff1c1c" v-if="item.status == '否'">{{
              item.status
            }}</font>
            <font v-else>{{ item.status }}</font>
          </td>
        </tr>
      </table>
    </div>

    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      title: "学习证明",
      proveDetail: "",
    };
  },
  computed: {
    uid() {
       return this.$store.state.uid;
    },
  },
  activated() {
    this.getUserInfo();
  },
  deactivated() {
    this.proveDetail = "";
  },
  methods: {
    async getProve() {
      var student_id = this.$route.query.id;
      var uid = this.uid;
      var data = {
        student_id,
        uid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/study/prove", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "学习中心 学习证明：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.proveDetail = res.data;
      } else {
        this.proveDetail = "";
      }
    },
    // 获取用户信息
    async getUserInfo() {
      if (!this.uid) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
          return ;
        }
     		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
      } else {
        this.getProve();
      }
    },
  },
};
</script>

<style scoped>
.dataUpload-box {
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  background: #ffffff;
  position: relative;
  padding: 0 0.3rem 0.3rem;
}
.dataUpload-box .icon {
  width: 2.07rem;
  height: 2.07rem;
  position: absolute;
  top: 2.14rem;
  left: 2.26rem;
}
.dataUpload-box .cate-title {
  font-size: 0.32rem;
  color: #333333;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.02rem solid #b5b5b5;
  margin-bottom: 0.3rem;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.table td {
  width: 60%;
  min-height: 0.4rem;
  padding: 0.1rem 0;
  font-size: 0.24rem;
  border: 0.02rem solid #b5b5b5;
  color: #333333;
  text-align: end;
  text-align: right;
  padding-right: 0.2rem;
}
.table td.table-gray {
  width: 40%;
  text-align: end;
  text-align: right;
}
.table1 {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.table1 th {
  min-height: 0.4rem;
  padding: 0.2rem 0;
  font-size: 0.24rem;
  color: #333333;
  border: 0.02rem solid #b5b5b5;
  border-top: none;
  font-weight: normal;
  text-align: center;
}
.table1 td {
  min-height: 0.4rem;
  padding: 0.2rem 0;
  font-size: 0.24rem;
  color: #333333;
  border: 0.02rem solid #b5b5b5;
  text-align: center;
}
.table1 td:nth-child(1) {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  text-align: start;
}
.table .success_num {
  text-align: left;
  padding-left: 0.2rem;
}
</style>